<template>
  <div id="risk-component" class="mdc-layout-grid__inner risk-container">

    <div class="mdc-layout-grid__cell--span-4 right margin-risk">
      <div>
        <h3 id="title-text" class="risk-title">Control de Riesgo</h3>
        <p id="text-tool" class="risk-text">El control de riesgo es una de las principales características cuando usamos la metodología Eurekers. Con esta calculadora financiera, usted controlará el riesgo en cada una de sus operaciones.</p>   
      </div>

      <div class="crypto-toggle">
      <label class="switch">
        <input type="checkbox" v-model="showDecimals">
        <span class="slider"></span>
      </label>
      <span class="toggle-label">Crypto</span>
      </div>
      
      <div class="row">
        <InputTextOutline v-for="(item, index) in input_risk_first" :key="index" :ref="item.ref" :permission="item.permission" :character_counter_permission="item.character_counter_permission" :icon_permission="item.icon_permission" :helper_permission="item.helper_permission" :input_key_to_save_user_values="item.input_key_to_save_user_values" :input_class_character_counter="item.input_class_character_counter" :icon_class="item.icon_class" :icon_name="item.icon_name" :input_label="item.input_label" :input_helper="item.input_helper" :has_focus="item.has_focus" :length="item.length" :computed_value="item.computed_value" :computed_value_permission="item.computed_value_permission" :test_field_is_numeric="item.test_field_is_numeric" @_emit_method_order_input="_getValueFromInputRisk" />

        <SelectOutline v-for="(item, index) in select_risk" :key="'A'+index" :ref="item.ref" :label_name="item.label_name" :options="item.options" :component_class="item.component_class" :helper_select_permission="item.helper_select_permission" :helper_text_select="item.helper_text_select" @_emit_method_order_select="_getValueFromSelect" />

        <InputTextOutline v-for="(item, index) in input_risk" :key="'B'+index" :ref="item.ref" :permission="item.permission" :character_counter_permission="item.character_counter_permission" :icon_permission="item.icon_permission" :helper_permission="item.helper_permission" :input_key_to_save_user_values="item.input_key_to_save_user_values" :input_class_character_counter="item.input_class_character_counter" :icon_class="item.icon_class" :icon_name="item.icon_name" :input_label="item.input_label" :input_helper="item.input_helper" :input_value="item.input_value" :has_focus="item.has_focus" :length="item.length" :computed_value="risk_user_currency_operation" :computed_value_permission="item.computed_value_permission" @_emit_method_order_input="_getValueFromInput" :test_field_is_numeric="item.test_field_is_numeric" />
      </div>
      
      <hr>
      
      <div class="text-container">
        <div class="text-box">
          <p id="text-actions" class="text-grey">NÚMERO DE ACCIONES A COMPRAR</p>
          <p id="result-actions" class="text-black">{{ formatNumber(stock_to_buy) }}</p>
        </div>
        <div class="text-box">
          <p id="text-cotization" class="text-grey">COTIZACIÓN DE LA DIVISA ACTUAL</p>
          <p id="result-cotization" class="text-black">{{ formatNumber(currency_operation_change_value, true) }}</p>
        </div>
        <div class="text-box">
          <p id="text-montante" class="text-grey">MONTANTE</p>
          <p id="result-montante" class="text-black">{{ formatNumber(amount_in_euros_value) }}</p>
        </div>
        <div class="text-box">
          <p id="text-montante-euros" class="text-grey">MONTANTE EN EUROS</p>
          <p id="result-montante-euros" class="text-black">{{ formatNumber(amount_in_currency_value) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputTextOutline from '@/components/helpers/InputTextOutline.vue';
import SelectOutline from '@/components/helpers/SelectOutline.vue';
import inputRiskFirst from './configs/risk/input_risk_first.json';
import inputRisk from './configs/risk/input_risk.json';
import selectRisk from './configs/risk/select_risk.json';
import APICaller from "@/components/codeComponents/APICaller.js";

export default {
  mixins:[APICaller],
  name: 'Risk',
  metaInfo() {
    return {
      title: 'Control de riesgo Eurekers',
      meta:[
        { name: 'description', content: "El control de riesgo es una de las principales características cuando usamos la metodología Eurekers. Con esta calculadora financiera, usted controlará el riesgo en cada una de sus operaciones."}
      ]
    }
  },
  components: {
      InputTextOutline,
      SelectOutline
  },
  data() {
    return {
      input_risk_first: inputRiskFirst,
      input_risk: inputRisk,
      select_risk: selectRisk,
  
      risk_input_value: '',
      entry_input_value: '',
      exit_input_value: '',
      active_currency_user: '',
      active_currency_operation: '',

      stock_to_buy: '',
      amount_in_currency_value: '',
      amount_in_euros_value: '',
      currency_user_change_value: '',
      currency_operation_change_value: '',
      risk_user_currency_operation: '',

      riskInputMaterialComponent: '',
      entryInputMaterialComponent: '',
      exitInputMaterialComponent: '',
      showDecimals: false,
    };
  },
  methods: {

    _getValueFromInput(value) {
      if(value[1] == 'entry') {
        this.entry_input_value = value[0]
      }

      if (value[1] == 'exit') {
        this.exit_input_value = value[0]
      }

      if(!this.stock_to_buy == '') {
        this._calculateRisk();
      }

      if(!this.entry_input_value == ''){
        this._calculateRisk();
      }
    },

    _getValueFromInputRisk(value) {
      this.risk_input_value = value[0]

      if (!this.risk_user_currency_operation == ''){
        this._calculateRisk();
      }
    },

    _getValueFromSelect(value) {
      if(value[1] == 'currency'){
        this.active_currency_user = value[0]
      }
      if(value[1] == 'currency_opertation') {
        this.active_currency_operation = value[0]
      }

      if(!this.stock_to_buy == '') {
        this._calculateRisk();
      }

      setTimeout(() => {
        this._calculateUserCurrency();
      },500);

      if(!this.active_currency_operation == ''){
          this._getUserCurrencyChange();
      }

      if(!this.stock_to_buy == '') {
        this._calculateRisk();
      }
    },

    _getUserCurrencyChange(){
      let currency = this.active_currency_user;
      let currency_operation = this.active_currency_operation;

      let success = response=> {
        let data = response.data.chart.result[0].meta.chartPreviousClose.toFixed(3);
        this.currency_user_change_value = parseFloat(data);

        if(!this.currency_user_change_value == null) {
          this.$vueOnToast.pop('error', 'Ha habido un error', 'Selecciona otra divisa');
        } 
      }

      let url = '/api/v1/calculator-exchanges/' + currency + '/' + currency_operation

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('204', 'Error, seleccione otra divisa.');

      this._getAPICall(url, successHandler, failureHandler);
    },

    _getEuroCurrencyChange(){
      let currency_operation = this.active_currency_operation;

      let success = response => {
        let data = response.data.chart.result[0].meta.chartPreviousClose.toFixed(3);
        this.currency_operation_change_value = parseFloat(data);
      }

      let url = '/api/v1/calculator-exchanges/EUR/'+currency_operation;

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('205', 'Error, seleccione otra divisa.');

      this._getAPICall(url, successHandler, failureHandler);
      return true;
    },

    _calculateRisk() {
      var result = this._getEuroCurrencyChange();
      let risk = setInterval(() => {
        if(result == true){
          let risk_change = this.risk_input_value * this.currency_user_change_value;
          this.stock_to_buy = (risk_change / (this.entry_input_value - this.exit_input_value)).toFixed(2);

          this.amount_in_currency_value = ((this.stock_to_buy * this.entry_input_value) / this.currency_operation_change_value).toFixed(2);

          // euros
          let risk_change_euro = this.risk_input_value * this.currency_operation_change_value
          let stock_to_buy_euro = (risk_change_euro / (this.entry_input_value - this.exit_input_value)).toFixed(2)
          let amount_in_euros_value = (stock_to_buy_euro * this.entry_input_value).toFixed(2)
          this.amount_in_euros_value = amount_in_euros_value;
          clearInterval(risk);         
        }        
      }, 500);
    },

    _calculateUserCurrency() {
      this.risk_user_currency_operation = (this.risk_input_value * this.currency_user_change_value).toFixed(2);
    },

    formatNumber(value, isExchangeRate = false) {
      if (value == 0 || isNaN(value) || !isFinite(value)) {
        return this.showDecimals ? '0.00' : '0';
      }
      
      const num = Number(value);
      if (isExchangeRate) {
        // Always show 3 decimals for exchange rates
        return num.toFixed(3);
      }
      
      return this.showDecimals ? num.toFixed(2) : Math.round(num).toString();
    },
  }
}
</script>



<style lang="scss" scoped>
  @use "@material/list/mdc-list";
  @use "@material/menu-surface/mdc-menu-surface";
  @use "@material/menu/mdc-menu";
  @use "@material/select/styles";
  @use "@material/data-table/data-table";
  @include data-table.core-styles;
  @include data-table.theme-baseline;

  $color_black: #00000B;
  $color_dark: #000010;
  $color_grey: rgba(0, 0, 0, 0.6);
  $back_grey: #F3F4F7;

  h3, p {
    color: var(--color-text-primary) !important;
  }

  .margin-risk {
    margin-top: 30px;}
  .risk-container{
    margin-left: 70px;}
  hr{
    border-top: 1px rgba(46, 45, 44, 0.08) solid;
    margin: 30px 20px;}
  .risk-title {
    color: $color_dark;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;}
  .risk-text {
    margin-bottom: 30px;
    color: $color_dark;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;}
  .text-box {
    display: flex;
    justify-content: space-between;}
  .text-grey{
    color: $color_grey;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;}
  .text-black{
    color: $color_black;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.15px;}
  .select-container-size,
  .input-container-size {
    margin-bottom: 22px;}
  
  @media (max-width: 599px){
    .mdc-layout-grid__cell {
      margin: 0;}
    .select-container-size {
      flex: 0 0 48%;
      max-width: 100%;}
    .input-container-size {
      flex: 0 0 48%;
      max-width: 100%;} 
    .risk-container{
      margin-left: 0px;
    } 
  }
  @media (min-width: 600px) and (max-width: 839px) {
    .margin-risk {
      margin: 70px 20px 0;}
    .mdc-layout-grid__cell--span-4.right {
      grid-column-end: span 8;}
    .select-container-size,
    .input-container-size {
      flex: 0 0 48%;
      max-width: 100%;}
    .row {
      display: flex;
      flex-wrap: wrap;
      flex: 1 1 auto;
      justify-content: space-between;}
    .text-box {
      margin: 0 20px;}
  }
  @media (min-width: 840px){
    .margin-risk {
      margin: 70px 20px 0;}
    .mdc-layout-grid__cell--span-8.left {
      grid-column-end: span 4;
      background-color: $back_grey;
      min-height: 100vh;
      height: 100%;
      margin: 0;}
    .mdc-layout-grid__cell--span-4.right {
      grid-column-end: span 8;
      margin-right: 36px;}
    .select-container-size, 
    .input-container-size {
      flex: 0 0 48%;
      max-width: 100%;}
    .row {
      display: flex;
      flex-wrap: wrap;
      flex: 1 1 auto;
      justify-content: space-between;}
    .text-box {
      margin: 0 20px;}
  }
  @media (min-width: 1280px){
    .mdc-layout-grid__cell--span-8.left {
     grid-column-end: span 8; }
    .mdc-layout-grid__cell--span-4.right {
     grid-column-end: span 4; }
    .row {
      display: flex;
      flex-wrap: wrap;
      flex: 1 1 auto;
      justify-content: space-between;}
    .text-box {
      margin: 0 20px;}
  }

  .crypto-toggle {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .toggle-label {
    margin-left: 10px;
    color: var(--color-text-primary);
    font-size: 14px;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }

  input:checked + .slider {
    background-color: var(--color-highlight);
  }

  input:checked + .slider:before {
    transform: translateX(26px);
  }
</style>
